<template>
  <sm-page-loader v-if="childList.isLoading" />
  <div v-else class="editable-list">
    <sm-breadcrumbs class="editable-list__breadcrumbs" :items="breadcrumbs" />

    <sm-datatable
      class="editable-list__datatable"
      :caption="tableCaption"
      :headers="tableHeaders"
      :items="childList.data"
    />
  </div>
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('editableList');
// components
import SmPageLoader from '@/components/common/SmPageLoader.vue';
import SmBreadcrumbs from '@/components/common/breadcrumbs/SmBreadcrumbs.vue';
import SmDatatable from '@/components/common/SmDatatable.vue';

export default {
  name: 'OneCContactsForCustomer',

  components: {
    SmPageLoader,
    SmBreadcrumbs,
    SmDatatable,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'OneCContacts',
      parentName: 'Customer',
      tableCaption: 'Контакты',
      tableHeaders: [
        { text: 'Фамилия', alias: 'surname', order: 'surname' },
        { text: 'Имя', alias: 'name', order: 'name' },
        { text: 'Отчество', alias: 'lastName', order: 'lastName' },
        { text: 'Дата рождения', alias: 'birthday', order: 'birthday' },
        { text: 'Должность', alias: 'position', order: 'position' },
        { text: 'Адрес', alias: 'adress', order: 'adress' },
        { text: 'Телефон', alias: 'phone', order: 'phone' },
        { text: 'Почта', alias: 'email', order: 'email' },
      ],
    };
  },

  computed: {
    ...mapState(['item', 'childList']),

    id() {
      return +this.$route.params.id;
    },

    breadcrumbs() {
      return [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Клиенты',
          route: { name: 'Customers' },
        },
        {
          text: `Редактирование клиента ${this.item.data?.name}`,
          route: { name: 'CustomerEdit', params: { id: this.id } },
        },
        {
          text: 'Контакты',
        },
      ];
    },
  },

  created() {
    this.getItem({ name: 'Customers', id: this.id });

    this.getChildList({
      name: this.controllerName,
      parentName: this.parentName,
      parentId: this.id,
      params: {
        skip: 0,
        count: 150,
      },
    });
  },

  methods: {
    ...mapActions(['getItem', 'getChildList']),
  },
};
</script>

<style lang="scss">
.editable-list__breadcrumbs {
  margin-bottom: 15px;
}
</style>
